<template>
    <div>
        <Navbar :page="`Evidências da atividade: ${form.nome}`" link="/mapeamento" nameLink="Mapeamento" />
        <div class="mx-1 md:mx-5 my-8">
            <div class="mt-5 md:mt-0">
                
                <form action="#" method="POST">

                    <div class="grid grid-cols-12 mt-3">
                        <div class="col-span-12">
                            <label for="anexosEvidencias" class="block text-sm font-medium">
                                <b>Evidências</b>, Anexe aqui arquivo(s) que faça(m) sentido para você. A Plataforma permite o upload (subir) arquivos em qualquer formato (imagem, audio, docs, pdf).
                            </label>
                            <div class="grid grid-cols-12 mt-2 mb-2">
                                <div class="col-span-4">
                                    <vue-dropzone ref="anexosEvidencias" id="anexosEvidencias" class="" :options="dropzoneOptions" v-on:vdropzone-success="updateAnexosEvidencias"></vue-dropzone>
                                </div>
                                <div class="col-span-8">
                                    <a :href="`${url_api}/v1/atividades/downloadevidencias/${form._id}?token=${token}`" target="_blank" class="bg-terciary ml-5 text-white p-2">Baixar todas as evidências (formato zip)</a>
                                </div>
                            </div>
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Ref
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Evidência / Nome do arquivo
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Tipo / Formato
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Descrição
                                        </th>
                                        <th  scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Opção
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(item, idx) in this.form.anexosEvidencias" :key="item._id">
                                        <td class="px-6 py-4 ">
                                            {{ idx + 1 }}
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                <div class="text-sm text-gray-500">
                                                    {{item.originalname ? item.originalname : ''}}
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                <div class="text-sm text-gray-500">
                                                    {{item.extensionName ? item.extensionName : ''}}
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                <div class="text-sm text-gray-500">
                                                    <input type="text" v-model="item.descricao">
                                                </div>
                                            </div>
                                        </td>
                                        <td  class="px-6 py-4">
                                            <div class="flex items-center">
                                                <div class="text-sm text-blue-500">
                                                    <a target="_blank" :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">Visualizar / Baixar</a>
                                                    <button
                                                    type="button"
                                                    @click="form.anexosEvidencias.splice(idx, 1)" 
                                                    class="inline-flex justify-center py-1 px-2 mx-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ml-4">
                                                    Remover
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>

                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save()" type="button" class="inline-flex justify-center py-2 px-6 m-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                Salvar
                            </button>
                        </div>
                    </div> 
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import  moment from 'moment';

export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            token: localStorage.getItem('token'),
            form: {
                nome: '',
                descricao: '',
                versao: '',
                agente: null,
                empresa: null,
                setor: null,
                finalidade: null,
                responsavel: null,
                pessoa_responsavelQMA: null,
                profissional_responsavelQMA:  null,
                tipo_responsavelQMA: '',
                consultor_responsavelQMA: null,
                entrevistado: null,
                tipotratamentodados: [], 
                fasesdados: [],
                formatodadopessoal: null, 
                dados: null,
                dadossensiveis: null,
                categoriasdados: null,
                tipotitular: [],
                canais: null,
                qtd: '',
                frequencia: '',
                outraFrequencia: '',
                titularmenor: false,
                consetimentopais: false,
                baselegal: [],
                legislacoes: null,
                testeInteresse: false,
                armazenamento: null,
                prazoRetencao: 0,
                tipoPrazoRetencao: '',
                formaDescarte: '',
                origemAcesso: false,
                origemAcessoPessoas: [],
                comoOrigemAcessoPessoas: '',
                compartilhadaSetor: false,
                comoCompartilhadaSetor: '',
                setoresCompartilhado: [],
                compartilhadaGrupoEconomico: false,
                grupoEconomicoCompartilhado: [],
                compartilhadaEmpresa: false,
                empresasCompartilhado: [],
                compartilhadaEmpresaEstrangeira: false,
                empresasEstrangeiraCompartilhada: [],
                decisaoAutomatizada: false,
                descricaoDecisaoAutomatizada: '',
                medidasSeguranca: [],
                medidasSegurancaTecnica: [],
                finalizado: false,
                infoPreliminares: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                dadosColetados: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                legitimidade: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                cicloDeVida: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                acessoDados: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                medidas: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                gestaoDeRisco: {
                    conformidades: true,
                    cenario: [
                        {
                            real: '',
                            ideal: ''
                        }
                    ],
                    evidencias: []
                },
                obsConformidades: '',
                principiosAtendidos: [
                    {
                        "entende": false,
                        "principio": "I",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "II",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "III",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "IV",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "V",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "VI",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "VII",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "VIII",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "IX",
                        "obs": ""
                    },
                    {
                        "entende": false,
                        "principio": "X",
                        "obs": ""
                    },
                ],
                avaliacao_preliminar: {
                    ativos: [],
                    vulnerabilidades: [],
                    ameacas: [],
                    condutas: [],
                    probabilidade: '',
                    impacto: '',
                    grauderisco: '',
                    obs: '',
                },
                microatividades: [],
                orientacoes: [
                    { data: '', texto: '', quem: '' },
                ],
                quantidadeTitulares: [],
                anexosEvidencias: [],
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste sua evidência aqui',
                dictRemoveFile: 'Remover',
            }),
            moment
        }
    },
    methods: {
        async save() {
            const req = await this.$http.put(`/v1/atividades`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: '/mapeamento'});
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async updateAnexosEvidencias(file, response) {
            this.$refs.anexosEvidencias.removeFile(file);
            this.form.anexosEvidencias.push(response.file);
        },
    },
    async beforeMount() {
        let loader = this.$loading.show({
            container: null,
            canCancel: true
        });
        
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/atividades/${id}`);
            this.form = req.data;
        }

        loader.hide();
    },
}
</script>